@font-face
{
font-family: 'BaminiPlain';
src: url('BaminiPlain.eot');
src: url('BaminiPlain.eot?#iefix') format('embedded-opentype'),
	 url('BaminiPlain.woff') format('woff'),
	 url('BaminiPlain.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}
.lang-ta
{
font-family: 'BaminiPlain';
font-size:18px !important;
}

input.lang-ta
{
font-family: 'BaminiPlain';
font-size:18px !important;
}

select.lang-ta
{
font-family: 'BaminiPlain';
font-size:13px !important;
}

.lang-en
{
font-family:Verdana, Arial, Helvetica, sans-serif;
font-size:15px !important;
}

input.lang-en
{
font-family:Verdana, Arial, Helvetica, sans-serif;
font-size:15px !important;
}

select.lang-en
{
font-family:Verdana, Arial, Helvetica, sans-serif;
font-size:15px !important;
}
.profileview
{
  font-size:21px;
}

.form-control{
	color:#333;
	font-weight: 500;
}
.form-horizontal .control-label{
	font-size:13px !important;
}
@media print {
 .page-break  { display: block; page-break-before: always; }
}

 /* Style the Image Used to Trigger the Modal */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content, #caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
} 

.bold{
	font-weight:600;
	font-size:13px;
	color:#555555;
}
.fontsmall{
	font-size: 13px;
}
